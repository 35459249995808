import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { PatientPreferencesGraphqlService } from '@app/account/connected-accounts/patient-preferences-graphql.service';
import { FeatureFlagSelectors } from '@app/core/feature-flags/feature-flag.selectors';
import { FeatureFlags } from '@app/core/feature-flags/feature-flags';
import { LinksService } from '@app/core/links.service';

import { AppStoreRedirectService } from '../../app-store-redirect.service';
import { User } from '../../user';
import { NavbarAnalyticsService } from '../navbar-analytics.service';

export enum Menu {
  MAIN = 'MAIN',
  HEALTH_RECORD = 'HEALTH_RECORD',
  ACCOUNT = 'ACCOUNT',
}

@Component({
  selector: 'om-navbar-mobile',
  templateUrl: 'navbar-mobile.component.html',
  styleUrls: ['../navbar-common.scss', 'navbar-mobile.component.scss'],
})
export class NavbarMobileComponent implements OnInit {
  @Input() enrolledInMindsetPlus: boolean;
  @Input() inviteCta: string;
  @Input() canRegisterKid: boolean;
  @Input() inactiveMembership: boolean;
  @Input() canBookVisit: boolean;
  @Input() set user(user: User) {
    this.directSignupEligible = user.isDirectSignupEligible;
    this.virtual = user.isVirtual;
    this.pediatric = user.isPediatric();
  }

  @Output() inviteModalClicked = new EventEmitter<void>();
  @Output() logoutClicked = new EventEmitter<MouseEvent>();

  mobileAppLink: string | void;
  connectedAccountsActive$: Observable<boolean>;
  pediatric: boolean;
  virtual: boolean;
  directSignupEligible: boolean;
  recordsDownloadEnabled$: Observable<boolean>;
  menu = Menu;
  menuOpen = {
    [Menu.MAIN]: false,
    [Menu.HEALTH_RECORD]: false,
    [Menu.ACCOUNT]: false,
  };

  private readonly ANALYTICS_APP_LINK_LOCATION = 'slide-menu';

  constructor(
    private navbarAnalyticsService: NavbarAnalyticsService,
    private featureFlagSelectors: FeatureFlagSelectors,
    private patientPreferencesGraphqlService: PatientPreferencesGraphqlService,
    private appStoreRedirectService: AppStoreRedirectService,
    public links: LinksService,
  ) {}

  trackMessagesLink = () => this.navbarAnalyticsService.trackMessagesLink();
  trackHomeLink = () => this.navbarAnalyticsService.trackHomeLink();
  trackRegisterChildLink = () => this.navbarAnalyticsService.trackRegisterChildLinkSelected();
  trackDirectSignupLink = () => this.navbarAnalyticsService.trackDirectSignupStarted();
  trackBookVisitLink = () => this.navbarAnalyticsService.trackBookVisitLink();
  trackTasksLink = () => this.navbarAnalyticsService.trackTasksLink();

  ngOnInit(): void {
    this.recordsDownloadEnabled$ = this.featureFlagSelectors.getFeatureFlag(
      FeatureFlags.MEDICAL_RECORDS_DOWNLOAD,
      false,
    );

    this.connectedAccountsActive$ = this.patientPreferencesGraphqlService.watch().valueChanges.pipe(
      map(preferences => {
        const { fitbitAuthorized, healthkitAuthorized } = preferences.data.patient;
        return fitbitAuthorized || healthkitAuthorized;
      }),
    );

    this.mobileAppLink = this.appStoreRedirectService.mobileRedirectUrl;
  }

  toggleMenu(menu: Menu, event?: MouseEvent) {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
      (<HTMLElement>event.target).blur();
    }

    this.menuOpen[menu] = !this.menuOpen[menu];
  }

  closeMenus() {
    (<Menu[]>Object.keys(this.menuOpen)).forEach(menu => (this.menuOpen[menu] = false));
  }

  handleAppLinkClick() {
    this.navbarAnalyticsService.appStoreLinkClicked(this.ANALYTICS_APP_LINK_LOCATION);
  }
}
