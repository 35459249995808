import { Component, OnDestroy, OnInit } from '@angular/core';
import { ApolloQueryResult } from '@apollo/client/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { combineLatest, Observable, Subject } from 'rxjs';
import { map, shareReplay, takeUntil } from 'rxjs/operators';

import { PatientPreferencesGraphqlService } from '@app/account/connected-accounts/patient-preferences-graphql.service';
import {
  MembershipResult,
  MembershipResult_membership_plan_B2bPlan as B2bPlan,
} from '@app/core/__generated__/MembershipResult';
import { FeatureFlagSelectors } from '@app/core/feature-flags/feature-flag.selectors';
import { FeatureFlags } from '@app/core/feature-flags/feature-flags';
import { LinksService } from '@app/core/links.service';
import { LoginService } from '@app/core/login.service';
import { Membership, MembershipPlan } from '@app/core/membership';
import { MembershipGraphQL } from '@app/core/membership-graphql.service';
import { MembershipService } from '@app/core/membership.service';
import { UserService } from '@app/core/user.service';
import { ProgramHubComponent } from '@app/program/program-hub/program-hub.component';
import { Features } from '@app/shared/active-feature-flag-collection';
import { AppStoreRedirectService } from '@app/shared/app-store-redirect.service';
import { BookVisitCtaService } from '@app/shared/book-visit-cta.service';
import { FeatureFlagService } from '@app/shared/feature-flag.service';
import { Menu } from '@app/shared/navbar/navbar-mobile/navbar-mobile.component';
import { InviteFriendsModalComponent } from '@app/shared/ribbon-banner/referrals/invite-friends-modal.component';
import { User } from '@app/shared/user';
import { Profile } from '@omgui/omgui-profile-bubble/omgui-profile-bubble.component';

import { ProgramEnrollmentGraphQL } from '../program-enrollment-graphql.service';
import { NavbarAnalyticsService } from './navbar-analytics.service';

@Component({
  selector: 'om-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit, OnDestroy {
  static NAVBAR_HEIGHT = 84;
  static ANALYTICS_APP_LINK_LOCATION = 'slide-menu';

  private destroy$ = new Subject();

  Menu = Menu;
  menuOpen = {
    [Menu.MAIN]: false,
    [Menu.HEALTH_RECORD]: false,
    [Menu.ACCOUNT]: false,
  };

  mobileAppLink: string | void;
  profile: Profile;
  inviteCta = 'Invite Friends';
  adultConsumerMember = false;
  b2bCoreMember = false;
  connectedAccountsPageActive = false;
  requestRecords = false;
  enrolledInMindsetPlus$: Observable<boolean>;
  user$: Observable<User>;
  membership$: Observable<Membership>;
  isPediatric$: Observable<boolean>;

  trackHealthRecordLink = this.navbarAnalyticsService.trackHealthRecordLink.bind(this.navbarAnalyticsService);
  trackBookVisitLink = this.navbarAnalyticsService.trackBookVisitLink.bind(this.navbarAnalyticsService);
  trackTasksLink = this.navbarAnalyticsService.trackTasksLink.bind(this.navbarAnalyticsService);
  trackHomeLink = this.navbarAnalyticsService.trackHomeLink.bind(this.navbarAnalyticsService);
  trackMessagesLink = this.navbarAnalyticsService.trackMessagesLink.bind(this.navbarAnalyticsService);
  trackRegisterChildLink = this.navbarAnalyticsService.trackRegisterChildLinkSelected.bind(this.navbarAnalyticsService);
  trackDirectSignupLink = this.navbarAnalyticsService.trackDirectSignupStarted.bind(this.navbarAnalyticsService);
  trackMindsetSelected = () =>
    this.navbarAnalyticsService.trackMyProgramSelected({ programName: ProgramHubComponent.MINDSET_PROGRAM_TITLE });

  constructor(
    private loginService: LoginService,
    private modal: NgbModal,
    public featureFlagService: FeatureFlagService,
    private featureFlagSelectors: FeatureFlagSelectors,
    private membershipService: MembershipService,
    public navbarAnalyticsService: NavbarAnalyticsService,
    private userService: UserService,
    private appStoreRedirectService: AppStoreRedirectService,
    private membershipGraphQL: MembershipGraphQL,
    private programEnrollmentGraphQL: ProgramEnrollmentGraphQL,
    private patientPreferencesGraphqlService: PatientPreferencesGraphqlService,
    public bookVisitCtaService: BookVisitCtaService,
    public linksService: LinksService,
  ) {}

  ngOnInit() {
    this.user$ = this.userService.getUser().pipe(shareReplay(1));
    this.isPediatric$ = this.user$.pipe(map(user => user.isPediatric()));
    this.membership$ = this.membershipService.getMembership().pipe(shareReplay(1));
    this.mobileAppLink = this.appStoreRedirectService.mobileRedirectUrl;

    this.featureFlagSelectors
      .getFeatureFlag(FeatureFlags.MEDICAL_RECORDS_DOWNLOAD, false)
      .subscribe(medicalRecordsDownloadEnabled => {
        this.requestRecords = medicalRecordsDownloadEnabled;
      });

    this.featureFlagSelectors
      .getFeatureFlag(FeatureFlags.MEDICAL_RECORDS_DOWNLOAD, false)
      .subscribe(medicalRecordsDownloadEnabled => {
        this.requestRecords = medicalRecordsDownloadEnabled;
      });

    const featureFlagsActive$ = this.featureFlagService.featuresActive$([
      Features.PEDIATRIC_CONSUMER_REGISTRATION_PROMOTION,
      Features.ACCOUNT_SETTINGS_CONNECTED_DEVICES,
    ]);

    this.initB2BCoreMembership();
    combineLatest([
      this.membership$,
      this.user$,
      this.patientPreferencesGraphqlService.watch().valueChanges,
      featureFlagsActive$,
    ])
      .pipe(takeUntil(this.destroy$))
      .subscribe(([membership, user, preferencesData, featuresActive]) => {
        this.profile = {
          lastName: user.lastName,
          preferredName: user.preferredName,
          profileImageUrl: user.profileImageUrl,
        };

        this.adultConsumerMember = membership.omMembershipType === 'Consumer' && !user.isPediatric();
        if (user.referralDiscount) {
          this.inviteCta = `Give ${user.referralDiscount.getDiscountString()} off`;
        }

        const { fitbitAuthorized, healthkitAuthorized } = preferencesData.data.patient;
        this.connectedAccountsPageActive =
          fitbitAuthorized || healthkitAuthorized || featuresActive[Features.ACCOUNT_SETTINGS_CONNECTED_DEVICES];
      });

    this.enrolledInMindsetPlus$ = combineLatest([
      this.featureFlagSelectors.getFeatureFlag(FeatureFlags.BHX_MEMBER_PROGRAM_EXPERIENCE, false),
      this.programEnrollmentGraphQL.enrollmentForProgram$('mindset+'),
    ]).pipe(map(([flagEnabled, programEnrollment]) => flagEnabled && !!programEnrollment));
  }

  initB2BCoreMembership() {
    this.membershipGraphQL
      .fetch()
      .pipe(
        map((result: ApolloQueryResult<MembershipResult>) => {
          const { membership } = result.data;
          if (
            !(membership.plan as B2bPlan)?.company?.includesDependent &&
            !(membership.planType === MembershipPlan.SELF_PAID)
          ) {
            this.b2bCoreMember = true;
          }
        }),
      )
      .subscribe();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  logout(e: MouseEvent) {
    e.preventDefault();
    this.loginService.logout().subscribe(response => {});
  }

  toggleMenu(menu: Menu, event?: MouseEvent) {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
      (<HTMLElement>event.target).blur();
    }

    this.menuOpen[menu] = !this.menuOpen[menu];
  }

  closeMenus() {
    (<Menu[]>Object.keys(this.menuOpen)).forEach(menu => (this.menuOpen[menu] = false));
  }

  handleAppLinkClick() {
    this.navbarAnalyticsService.appStoreLinkClicked(NavbarComponent.ANALYTICS_APP_LINK_LOCATION);
  }

  openInviteModal() {
    this.modal.open(InviteFriendsModalComponent);
  }
}
