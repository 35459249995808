import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';

import { FeatureFlagSelectors } from '@app/core/feature-flags/feature-flag.selectors';
import { FeatureFlags, HomeRedesignVariation } from '@app/core/feature-flags/feature-flags';
import { LinksService } from '@app/core/links.service';
import { ProgramHubComponent } from '@app/program/program-hub/program-hub.component';
import { Profile } from '@omgui/omgui-profile-bubble/omgui-profile-bubble.component';

import { User } from '../../user';
import { NavbarAnalyticsService } from '../navbar-analytics.service';

export enum NavbarRoutes {
  Home = '/',
  HealthRecord = '/health-record',
  CarePlan = '/care-plan',
  Messages = '/messages',
}

interface RouteInfoInterface {
  isActive: boolean;
  readonly exact?: boolean;
}

@Component({
  selector: 'om-navbar-desktop',
  templateUrl: './navbar-desktop.component.html',
  styleUrls: ['../navbar-common.scss', './navbar-desktop.component.scss'],
})
export class NavbarDesktopComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject<void>();

  @Input() enrolledInMindsetPlus: boolean;
  @Input() inviteCta: string;
  @Input() canRegisterKid: boolean;
  @Input() inactiveMembership: boolean;
  @Input() canBookVisit: boolean;
  @Input() set user(user: User) {
    this.profile = {
      lastName: user.lastName,
      preferredName: user.preferredName,
      profileImageUrl: user.profileImageUrl,
    };
    this.directSignupEligible = user.isDirectSignupEligible;
    this.virtual = user.isVirtual;
    this.colorComboSeed = user.id;
  }

  @Output() inviteModalClicked = new EventEmitter<void>();
  @Output() logoutClicked = new EventEmitter<MouseEvent>();

  colorComboSeed: number;
  virtual: boolean;
  directSignupEligible: boolean;
  profile: Profile;
  isHomeScreenRedesignOn$: Observable<boolean>;

  navbarRoutes = NavbarRoutes;

  routeInfo: Record<NavbarRoutes, RouteInfoInterface> = {
    [this.navbarRoutes.Home]: { isActive: false, exact: true },
    [this.navbarRoutes.HealthRecord]: { isActive: false },
    [this.navbarRoutes.CarePlan]: { isActive: false },
    [this.navbarRoutes.Messages]: { isActive: false },
  };

  constructor(
    private navbarAnalyticsService: NavbarAnalyticsService,
    public links: LinksService,
    private router: Router,
    private featureFlagSelectors: FeatureFlagSelectors,
  ) {}

  ngOnInit(): void {
    this.isHomeScreenRedesignOn$ = this.featureFlagSelectors
      .getFeatureFlag(FeatureFlags.HOME_SCREEN_REDESIGN, HomeRedesignVariation.OFF)
      .pipe(map(value => value !== HomeRedesignVariation.OFF));
    this.updateActiveLink();
    this.router.events
      .pipe(
        takeUntil(this.destroy$),
        filter(event => event instanceof NavigationEnd),
      )
      .subscribe(() => {
        this.updateActiveLink();
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private updateActiveLink(): void {
    Object.keys(this.routeInfo).forEach(key => {
      const pathsMatchMode = this.routeInfo[key].exact ? 'exact' : 'subset';
      this.routeInfo[key].isActive = this.router.isActive(key, {
        paths: pathsMatchMode,
        matrixParams: 'ignored',
        queryParams: 'ignored',
        fragment: 'ignored',
      });
    });
  }

  trackMessagesLink = () => this.navbarAnalyticsService.trackMessagesLink();
  trackHomeLink = () => this.navbarAnalyticsService.trackHomeLink();
  trackRegisterChildLink = () => this.navbarAnalyticsService.trackRegisterChildLinkSelected();
  trackDirectSignupLink = () => this.navbarAnalyticsService.trackDirectSignupStarted();
  trackBookVisitLink = () => this.navbarAnalyticsService.trackBookVisitLink();
  trackTasksLink = () => this.navbarAnalyticsService.trackTasksLink();
  trackHealthRecordLink = () => this.navbarAnalyticsService.trackHealthRecordLink();
  trackMindsetSelected = () =>
    this.navbarAnalyticsService.trackMyProgramSelected({ programName: ProgramHubComponent.MINDSET_PROGRAM_TITLE });
}
