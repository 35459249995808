import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Inject, Injectable, InjectionToken } from '@angular/core';
import Rollbar, { Configuration } from 'rollbar';

import { environment } from '../../environments/environment';

// This needs to be in this file to have typescript
// accept global.CODE_VERSION getting injected by
// webpack.
declare let global: { CODE_VERSION: string };

const rollbarConfig: Configuration = {
  accessToken: environment.rollbarAccessToken,
  captureUncaught: true,
  payload: {
    environment: environment.rollbarEnvironment,
    client: {
      javascript: {
        source_map_enabled: true,
        code_version: global.CODE_VERSION,
      },
    },
    server: {
      root: 'webpack:///./', // https://docs.rollbar.com/docs/source-control#serverroot
    },
  },
};

export const RollbarService = new InjectionToken<Rollbar>('rollbar');

export function rollbarFactory() {
  return new Rollbar(rollbarConfig);
}

@Injectable()
export class RollbarErrorHandler implements ErrorHandler {
  constructor(@Inject(RollbarService) private rollbar: Rollbar) {}

  handleError(err: any): void {
    const error = err.originalError || err;
    if (environment.rollbarAccessToken) {
      this.rollbar.error(this.getErrorMessage(error));
    } else {
      console.error(error);
    }
  }

  /*
   * Rollbar's SDK expects a string or Error type, or else will log "Item sent with null or missing arguments"
   * Since Angular has it's own error response (like HttpErrorResponse) that rollbar doesn't recognize, we handle each
   * error case separately. The last two cases for "Undefined Error" are to catch any other cases, logging the unknown
   * error's constructor if it exists.
   * See: https://github.com/rollbar/rollbar.js/issues/975
   */
  private getErrorMessage(error: Error | string | HttpErrorResponse | any): Error | string {
    if (error instanceof Error || typeof error === 'string') {
      return error;
    } else if (error?.message) {
      return error.message;
    } else if (error?.constructor?.name) {
      return `Undefined Error ${error.constructor.name}: ${error}`;
    }

    return `Undefined Error: ${error}`;
  }
}
