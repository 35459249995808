import { Directive, ElementRef, HostListener, Input, NgModule, OnInit, Optional } from '@angular/core';
import { Router, RouterLink, RouterLinkWithHref } from '@angular/router';
import { Observable, of as observableOf } from 'rxjs';
import { catchError, timeoutWith } from 'rxjs/operators';

import { WindowService } from './window.service';

export type RouterLinkCommand = any[] | string;

@Directive({
  selector: '[omTrackLink]',
})
export class TrackLinkDirective implements OnInit {
  @Input() target: string;
  @Input() omTrackLink?: () => Observable<unknown> | undefined;
  @Input() routerLink: RouterLinkCommand;
  @Input() state: Record<string, any>;

  constructor(
    private router: Router,
    @Optional() private link: RouterLink,
    @Optional() private linkWithHref: RouterLinkWithHref,
    private windowService: WindowService,
    private elementRef: ElementRef,
  ) {}

  ngOnInit() {
    if (!this.omTrackLink) {
      return;
    }

    this.disableLink(this.link || this.linkWithHref);
  }

  private disableLink(link: RouterLink | RouterLinkWithHref) {
    if (link) {
      link.routerLink = [];
    }
  }

  @HostListener('click', ['$event']) onClick(event: MouseEvent) {
    if (!this.omTrackLink) {
      return;
    }

    const newTab = event.metaKey || event.ctrlKey || this.target === '_blank';
    if (!this.routerLink) {
      event.preventDefault();
    }

    this.omTrackLink()
      .pipe(
        /*
         timeoutWith gives omTrackLink a reasonable amount of time to finish its async operation before navigating away
         from the current page, without, in the case of a slow operation, causing the patient too much friction.
        */
        timeoutWith(300, this.noop()),
        catchError(_error => this.noop()),
      )
      .subscribe(() => {
        if (this.routerLink) {
          this.navigateInternally(newTab);
        } else {
          this.navigateExternally(newTab);
        }
      });
  }

  private navigateInternally(newTab: boolean): void {
    if (newTab) {
      return;
    }

    const pathFragments = Array.isArray(this.routerLink) ? this.routerLink : [this.routerLink];
    this.router.navigate(pathFragments, { state: this.state, queryParamsHandling: 'preserve' });
  }

  private navigateExternally(newTab: boolean): void {
    const href = this.elementRef.nativeElement.getAttribute('href');
    this.windowService.redirect(href, { newTab });
  }

  private noop(): Observable<null> {
    return observableOf(null);
  }
}

@NgModule({
  declarations: [TrackLinkDirective],
  exports: [TrackLinkDirective],
})
export class TrackLinkModule {}
