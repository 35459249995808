<div [ngClass]="{ 'border rounded': !borderless }">
  <h6 class="pt-3 title" *ngIf="title">{{ title }}</h6>

  <ul class="list-group list-group-flush d-none d-sm-block rounded">
    <li class="list-group-item d-flex align-items-center" data-cy="list-group-item" *ngFor="let item of items">
      <ng-container *ngTemplateOutlet="content; context: { $implicit: item }"></ng-container>
      <ng-container *ngIf="item.data.action?.route">
        <a class="btn btn-outline-primary col-3" [routerLink]="item.path">{{ item.data.action.label }}</a>
      </ng-container>
      <ng-container *ngIf="item.data.action?.href">
        <a class="btn btn-outline-primary col-3" [href]="item.path">{{ item.data.action.label }}</a>
      </ng-container>
    </li>
  </ul>

  <div class="list-group list-group-flush d-sm-none rounded">
    <ng-container *ngFor="let item of items">
      <ng-container *ngIf="!item.data.action?.href">
        <a class="list-group-item d-flex align-items-center list-group-item-action" [routerLink]="item.path">
          <ng-container *ngTemplateOutlet="content; context: { $implicit: item }"></ng-container>
        </a>
      </ng-container>
      <ng-container *ngIf="item.data.action?.href">
        <a class="list-group-item d-flex align-items-center list-group-item-action" [href]="item.path">
          <ng-container *ngTemplateOutlet="content; context: { $implicit: item }"></ng-container>
        </a>
      </ng-container>
    </ng-container>
  </div>
</div>

<ng-template #itemText let-item>
  <div class="flex-grow-1">
    <h6 class="m-0" data-cy="item-label">{{ item.label }}</h6>
    <span *ngIf="item.sublabel" [ngClass]="item.sublabelClass || bootstrapClass.Muted" data-cy="list-item-sublabel">
      {{ item.sublabel }}
    </span>
  </div>
</ng-template>

<ng-template #content let-item>
  <ng-container *ngTemplateOutlet="item.icon.templateRef"></ng-container>
  <ng-container *ngTemplateOutlet="itemText; context: { $implicit: item.data }"></ng-container>
</ng-template>
