import { Component, ElementRef, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';

@Component({
  selector: 'om-expanding-text-box',
  templateUrl: './expanding-text-box.component.html',
  styleUrls: ['./expanding-text-box.component.scss'],
})
export class ExpandingTextBox implements OnChanges {
  @Input() disabled: boolean;
  @Input() lined: boolean;
  @Input() placeholder: string;
  @Input() value: string;
  @Output() valueChange = new EventEmitter<string>();
  @Output() focused = new EventEmitter<Event>();
  @ViewChild('textarea', { static: true }) textarea: ElementRef;

  ngOnChanges(changes: SimpleChanges): void {
    // listen to value changes triggered from outside the component
    if (changes?.value?.previousValue === this.value) {
      this.value = changes.value.currentValue;
    }
  }

  clearMessage(): void {
    this.value = '';
  }

  isMessageEmpty(): boolean {
    return this.value == null || /^\s*$/.test(this.value);
  }

  focus(): void {
    this.textarea.nativeElement.focus();
  }

  handleValueUpdate() {
    this.valueChange.emit(this.value);
  }

  handleFocus(event: Event) {
    this.focused.emit(event);
  }
}
