import { Injectable } from '@angular/core';
import deepEqual from 'deep-equal';
import lodashMap from 'lodash-es/map';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';

import { ApiService } from '@app/core/api.service';
import { ServiceArea } from '@app/shared/service-area';

export interface ServiceAreaPediatricsResponse {
  pediatrics_available: boolean;
}

export const publicServiceAreaPath = '/api/v2/public/service_areas';

@Injectable()
export class ServiceAreaService {
  private _serviceAreas$ = new Subject<Array<ServiceArea>>();
  readonly serviceAreas$ = this._serviceAreas$.asObservable();

  /**
   * Service Area filtered
   */
  readonly nonVirtualServiceAreas$ = this.serviceAreas$.pipe(
    map(allServiceAreas => allServiceAreas.filter(serviceArea => !serviceArea.virtual)),
  );

  readonly virtualServiceAreas$ = this.serviceAreas$.pipe(
    map(allServiceAreas => allServiceAreas.filter(serviceArea => !!serviceArea.virtual)),
  );

  /**
   * Service Areas formatted for selection input options
   */
  readonly allServiceAreaOptions$ = this.serviceAreas$.pipe(
    map(serviceAreas => this.createServiceAreaOptions(serviceAreas)),
  );

  readonly nonVirtualServiceAreaOptions$ = this.nonVirtualServiceAreas$.pipe(
    map(serviceAreas => this.createServiceAreaOptions(serviceAreas)),
  );

  private _pediatricsAvailableInServiceArea$ = new Subject<boolean>();
  readonly pediatricsAvailableInServiceArea$ = this._pediatricsAvailableInServiceArea$.asObservable();

  constructor(private apiService: ApiService) {}

  getServiceAreas(b2bCompanyId?: number): Observable<Array<ServiceArea>> {
    const serviceAreaPath = b2bCompanyId
      ? `/api/v2/public/b2b_companies/${b2bCompanyId}/service_areas`
      : publicServiceAreaPath;

    this.apiService
      .get(serviceAreaPath)
      .pipe(
        map((result: any) => lodashMap(result, sa => ServiceArea.fromApiV2(sa))),
        map((allServiceAreas): ServiceArea[] =>
          allServiceAreas
            .filter(serviceArea => !serviceArea.registration_hidden)
            // Sort alphabetically and set the virtual service area last
            .sort((a, b) => (a.virtual === b.virtual ? 0 : a.virtual ? 1 : -1) || a.name.localeCompare(b.name)),
        ),
      )
      .subscribe(serviceAreas => this._serviceAreas$.next(serviceAreas));

    return this.serviceAreas$;
  }

  getServiceAreaByName(name: string) {
    return this.serviceAreas$.pipe(map(x => x.find(y => y.name === name)));
  }

  serviceAreaToUpdateIsNew(oldServiceArea: ServiceArea, currentServiceArea: ServiceArea) {
    return oldServiceArea == null || currentServiceArea == null || !deepEqual(oldServiceArea, currentServiceArea);
  }

  getPediatricsAvailability(serviceArea: ServiceArea): void {
    const pedsAvailablePath = `${publicServiceAreaPath}/${serviceArea.id}/pediatrics_available`;
    this.apiService.get(pedsAvailablePath).subscribe((response: ServiceAreaPediatricsResponse) => {
      this._pediatricsAvailableInServiceArea$.next(response.pediatrics_available);
    });
  }

  // Formats ServiceArea objects with custom messages used for selection options
  createServiceAreaOptions(serviceAreas): ServiceArea[] {
    return lodashMap(serviceAreas, serviceArea => {
      if (serviceArea.virtual) {
        serviceArea.name = `None of the above (${serviceArea.name})`;
      }
      return serviceArea;
    });
  }
}
