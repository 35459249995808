import { CommonModule } from '@angular/common';
import {
  Component,
  ContentChild,
  ContentChildren,
  Directive,
  Input,
  NgModule,
  OnInit,
  QueryList,
  TemplateRef,
} from '@angular/core';
import { Params, RouterModule } from '@angular/router';

import { appendQueryParamsToPath } from '@app/utils/link.utils';

export enum BootstrapTextColorClass {
  Muted = 'text-muted',
  Danger = 'text-danger',
}

export interface OmguiListCardItem {
  label: string;
  sublabel?: string;
  sublabelClass?: BootstrapTextColorClass;
  action?: OmguiListCardItemAction;
}

interface OmguiListCardItemAction {
  label: string;
  route?: string;
  href?: string;
  queryParams?: Params;
}

@Directive({
  selector: '[omgui-list-card-item-icon]',
})
export class OmguiListCardItemIconDirective {
  constructor(public templateRef: TemplateRef<any>) {}
}

@Directive({
  selector: 'omgui-list-card-item',
})
export class OmguiListCardItemDirective implements OnInit {
  @ContentChild(OmguiListCardItemIconDirective) icon: OmguiListCardItemIconDirective;

  /** The item to be rendered */
  @Input() data: OmguiListCardItem;

  path: string;

  ngOnInit(): void {
    if (!this.data.action) {
      return;
    }

    const { route, href, queryParams } = this.data.action;
    this.path = appendQueryParamsToPath(route || href, queryParams);
  }
}

@Component({
  selector: 'omgui-list-card',
  templateUrl: './omgui-list-card.component.html',
  styleUrls: ['./omgui-list-card.component.scss'],
})
export class OmguiListCardComponent {
  @ContentChildren(OmguiListCardItemDirective) items: QueryList<OmguiListCardItemDirective>;

  /** The title of the list of items */
  @Input() title: string;

  /** Whether or not the border should be present */
  @Input() borderless = false;

  readonly bootstrapClass = BootstrapTextColorClass;
}

@NgModule({
  declarations: [OmguiListCardComponent, OmguiListCardItemDirective, OmguiListCardItemIconDirective],
  imports: [CommonModule, RouterModule],
  exports: [OmguiListCardComponent, OmguiListCardItemDirective, OmguiListCardItemIconDirective],
})
export class OmguiListCardModule {}
