import { AgmCoreModule, LAZY_MAPS_API_CONFIG } from '@agm/core';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { LetModule } from '@rx-angular/template';
import { RecaptchaModule } from 'ng-recaptcha';
import { NgrxFormsModule } from 'ngrx-forms';

import { ProviderService } from '@app/account/my-provider/provider-graphql.service';
import { AppointmentSearchStateService } from '@app/appointment/appointment-search-service/appointment-search-state.service';
import { AppointmentSearchService } from '@app/appointment/appointment-search.service';
import { FeatureFlagSelectors } from '@app/core/feature-flags/feature-flag.selectors';
import { MembershipGraphQL } from '@app/core/membership-graphql.service';
import { PipesModule } from '@app/pipes/pipes.module';
import { AppointmentConfirmationModalComponent } from '@app/shared/appointment-confirmation-modal/appointment-confirmation-modal.component';
import { CouponService } from '@app/shared/coupon.service';
import { DropdownFormComponent } from '@app/shared/dropdown-form/dropdown-form.component';
import { EmailVerificationComponent } from '@app/shared/email-verification/email-verification.component';
import { ExcerptPipe } from '@app/shared/excerpt.pipe';
import { FeatureFlagByB2bIDGraphQL, FeatureFlagGraphQL } from '@app/shared/feature-flag-graphql.service';
import { NextRemoteVisitMultipleSlotsComponent } from '@app/shared/next-remote-visit-multiple-slots/next-remote-visit-multiple-slots.component';
import { NextRemoteVisitComponent } from '@app/shared/next-remote-visit/next-remote-visit.component';
import { OfficeService } from '@app/shared/office.service';
import { PatientExperimentService } from '@app/shared/patient-experiment.service';
import { ServiceAreaService } from '@app/shared/service-area.service';
import { StripePaymentRequestButtonComponent } from '@app/shared/stripe-credit-card/stripe-payment-request-button.component';
import { SvgRequestRecordsComponent } from '@app/shared/svg/svg-request-records/svg-request-records.component';
import { TrackLinkModule } from '@app/utils/track-link.directive';
import { OmguiModule } from '@omgui/omgui.module';

import {
  AccordionComponent,
  AddressAutocompleteComponent,
  AddressBasicInputComponent,
  AddressDisplayComponent,
  AddressOptionInputComponent,
  AddToCalendarComponent,
  AdultDirectSignupFormComponent,
  AmfValuePropsComponent,
  AnonymousFlagDataGraphQL,
  AnonymousFlagDataService,
  AppointmentMoreOptionsComponent,
  AppStoreRedirectService,
  BackButtonComponent,
  CardComponent,
  CardDirective,
  CardListModule,
  CardSliderComponent,
  CardTableModule,
  CarouselModalComponent,
  CheckboxComponent,
  CheckboxInputComponent,
  CheckmarkListComponent,
  ConfirmationModalComponent,
  ContentBlockService,
  CovidVaccinationNextSteps,
  CreateHealthInformationReleaseAuthorizationGraphqlService,
  CreditCardComponent,
  DateInputComponent,
  DeleteConfirmationInlineFormComponent,
  DescriptionListCardModule,
  DirectSignupBannerComponent,
  DirectSignupComponent,
  DownloadAppCardComponent,
  DownloadAppLinkInputComponent,
  DownloadAppModalComponent,
  DownloadAppModalContent,
  EmailInputComponent,
  EmailVerificationRegistrationComponent,
  ExpandingTextBox,
  ExpiredMembershipBannerComponent,
  FileSizePipe,
  FlashComponent,
  FocusTargetDirective,
  FooterComponent,
  HealthInformationReleaseFormComponent,
  HealthInformationReleaseService,
  IconItemComponent,
  InfoCardBodyDirective,
  InfoCardComponent,
  InfoCardHeaderDirective,
  InlineFormComponent,
  InternalRedirectPromptModalComponent,
  InviteFriendsModalComponent,
  LabelWrapper,
  LegalAgreementComponent,
  LegalDocComponent,
  LoggedInWrapperComponent,
  LoginPromptModalComponent,
  LogoSpinner,
  LogoutPromptModalComponent,
  MapsApiConfig,
  ModalTemplateComponent,
  MomentPipe,
  NameInputComponent,
  NavbarComponent,
  OfficeDetailsComponent,
  OmguiCheckboxComponent,
  OmguiClearableTextInputComponent,
  OmguiSummaryRowModule,
  OmRecaptchaComponent,
  PaginationComponent,
  PasswordInputComponent,
  PhoneNumberInputComponent,
  PhoneNumberPipe,
  PhoneNumberToLinkPipe,
  ProfileBubbleWithDetailsComponent,
  ProgramPromotionCardComponent,
  PusherService,
  RadioGroupComponent,
  RadioInputNgrxComponent,
  RedirectPromptModalComponent,
  ReferralBannerComponent,
  RoutingGroupComponent,
  RoutingGroupOptionComponent,
  SeparatorComponent,
  ServiceAreaSelectComponent,
  SexGenderInputComponent,
  SidebarComponent,
  SmallCardComponent,
  SmallCardSvgDirective,
  StartCasePipe,
  StateService,
  StripeCreditCardComponent,
  StripeService,
  StyledMapComponent,
  SubmitButton,
  TaskService,
  ToastComponent,
  ToggleComponent,
  TosComponent,
  TruncatedMarkdown,
  UrlToLinkPipe,
} from '.';
import { ConfigService } from '../core/config.service';
import { DateFormatPipe } from './date-format.pipe';
import { DateInputPickerComponent } from './date-input-picker/date-input-picker.component';
import { DetectResizeDirective } from './detect-resize/detect-resize.directive';
import { DetectResizeService } from './detect-resize/detect-resize.service';
import { EnterViewportObserverDirective } from './enter-viewport-observer.directive';
import { FooterAnalyticsService } from './footer/footer-analytics.service';
import { LabelWithTooltipComponent } from './label-with-tooltip/label-with-tooltip.component';
import { LoadingSpinnerComponent } from './loading-spinner/loading-spinner.component';
import { LottieComponent } from './lottie/lottie.component';
import { NativeAppService } from './native-app.service';
import { NavbarContainerComponent } from './navbar/navbar-container.component';
import { NavbarDesktopComponent } from './navbar/navbar-desktop/navbar-desktop.component';
import { NavbarMobileComponent } from './navbar/navbar-mobile/navbar-mobile.component';
import { OmguiBadgeComponent } from './omgui/omgui-badge/omgui-badge.component';
import { PaymentModalAnalyticsService } from './payment-modal/payment-modal-analytics.service';
import { PaymentModalComponent } from './payment-modal/payment-modal.component';
import { SafeHtmlPipe } from './safe-style-html.pipe';
import { SelfPayConfirmationComponent } from './self-pay-confirmation/self-pay-confirmation.component';
import {
  ArrowBackComponent,
  ArrowLeftComponent,
  ArrowNextComponent,
  ArrowRightComponent,
  CalendarComponent,
  CircleWithCheckmarkComponent,
  CloseComponent,
  ExclamationInBubbleComponent,
  LocationOnMapComponent,
  LogoComponent,
  LogoSmComponent,
  PencilComponent,
  PhoneComponent,
  ProviderComponent,
  ShowPasswordComponent,
  SpecialtyComponent,
  Svg,
  SvgAfterpayLogoComponent,
  SvgAllergyComponent,
  SvgApplepayLogoComponent,
  SvgBikeComponent,
  SvgCaretDownComponent,
  SvgCaretRightModule,
  SvgCaretUpComponent,
  SvgCheckmarkComponent,
  SvgCircleWithExclamationPointComponent,
  SvgCopyIcon,
  SvgDownloadAppComponent,
  SvgExclamationComponent,
  SvgEyeClosedComponent,
  SvgEyeOpenComponent,
  SvgFacebookLinkComponent,
  SvgFamilyComponent,
  SvgGermComponent,
  SvgGiftBoxIcon,
  SvgHamburgerComponent,
  SvgHealthInformationExchangeComponent,
  SvgInfoComponent,
  SvgInstagramLinkComponent,
  SvgLabResultComponent,
  SvgLinkedInLinkComponent,
  SvgMedicationComponent,
  SvgMembershipAndBillingComponent,
  SvgMessageComponent,
  SvgMessageOutlineComponent,
  SvgNotificationSettingsComponent,
  SvgPhoneInBlueCircle,
  SvgPhoneInGreenCircle,
  SvgProfileComponent,
  SvgProgressPieComponent,
  SvgSecurityComponent,
  SvgStethoscopeComponent,
  SvgTwitterLinkComponent,
  SvgVaccineComponent,
  SvgVirtualVisitComponent,
  SvgVitalsComponent,
  SvgWatchComponent,
  SvgYoutubeLinkComponent,
  ToggleCaretComponent,
  UserOnCompComponent,
  ValidationXComponent,
  XWithCircleComponent,
} from './svg';
import { SvgActionItemComponent } from './svg/svg-action-item/svg-action-item.component';
import { SvgVaccinesComponent } from './svg/svg-vaccines/svg-vaccines.component';
import { TooltipComponent } from './tooltip/tooltip.component';
import { ViewMoreComponent } from './view-more/view-more.component';

@NgModule({
  declarations: [
    AccordionComponent,
    AddressAutocompleteComponent,
    AddressBasicInputComponent,
    AddressDisplayComponent,
    AddressOptionInputComponent,
    AddToCalendarComponent,
    AmfValuePropsComponent,
    AppointmentConfirmationModalComponent,
    ArrowBackComponent,
    ArrowLeftComponent,
    ArrowNextComponent,
    ArrowRightComponent,
    BackButtonComponent,
    CalendarComponent,
    CardComponent,
    CardDirective,
    CardSliderComponent,
    CarouselModalComponent,
    CheckboxComponent,
    CheckboxInputComponent,
    CheckmarkListComponent,
    CircleWithCheckmarkComponent,
    CloseComponent,
    ConfirmationModalComponent,
    CovidVaccinationNextSteps,
    CreditCardComponent,
    DateInputComponent,
    DeleteConfirmationInlineFormComponent,
    DirectSignupComponent,
    AdultDirectSignupFormComponent,
    IconItemComponent,
    DirectSignupBannerComponent,
    DownloadAppCardComponent,
    DownloadAppLinkInputComponent,
    EmailInputComponent,
    EmailVerificationRegistrationComponent,
    ExcerptPipe,
    ExclamationInBubbleComponent,
    ExpandingTextBox,
    ExpiredMembershipBannerComponent,
    FileSizePipe,
    FlashComponent,
    FocusTargetDirective,
    FooterComponent,
    HealthInformationReleaseFormComponent,
    InfoCardBodyDirective,
    InfoCardComponent,
    InfoCardHeaderDirective,
    InlineFormComponent,
    InternalRedirectPromptModalComponent,
    InviteFriendsModalComponent,
    LabelWrapper,
    LegalAgreementComponent,
    LegalDocComponent,
    LocationOnMapComponent,
    LoggedInWrapperComponent,
    LoginPromptModalComponent,
    LogoComponent,
    LogoSmComponent,
    LogoSpinner,
    LogoutPromptModalComponent,
    SidebarComponent,
    MomentPipe,
    AppointmentMoreOptionsComponent,
    NameInputComponent,
    NavbarComponent,
    NextRemoteVisitComponent,
    NextRemoteVisitMultipleSlotsComponent,
    OfficeDetailsComponent,
    OmRecaptchaComponent,
    OmguiCheckboxComponent,
    OmguiClearableTextInputComponent,
    PaginationComponent,
    PasswordInputComponent,
    PaymentModalComponent,
    RedirectPromptModalComponent,
    PencilComponent,
    PhoneComponent,
    PhoneNumberInputComponent,
    PhoneNumberPipe,
    PhoneNumberToLinkPipe,
    ProfileBubbleWithDetailsComponent,
    ProgramPromotionCardComponent,
    ProviderComponent,
    RadioInputNgrxComponent,
    RadioGroupComponent,
    ReferralBannerComponent,
    RoutingGroupComponent,
    RoutingGroupOptionComponent,
    SelfPayConfirmationComponent,
    SeparatorComponent,
    ServiceAreaSelectComponent,
    SexGenderInputComponent,
    ShowPasswordComponent,
    SmallCardComponent,
    SmallCardSvgDirective,
    SpecialtyComponent,
    StartCasePipe,
    StripeCreditCardComponent,
    StripePaymentRequestButtonComponent,
    StyledMapComponent,
    SubmitButton,
    Svg,
    SvgActionItemComponent,
    SvgAfterpayLogoComponent,
    SvgAllergyComponent,
    SvgApplepayLogoComponent,
    SvgBikeComponent,
    SvgVitalsComponent,
    SvgCopyIcon,
    SvgCheckmarkComponent,
    SvgCircleWithExclamationPointComponent,
    SvgGermComponent,
    SvgDownloadAppComponent,
    SvgEyeClosedComponent,
    SvgEyeOpenComponent,
    SvgExclamationComponent,
    SvgFacebookLinkComponent,
    SvgFamilyComponent,
    SvgGiftBoxIcon,
    SvgHamburgerComponent,
    SvgHealthInformationExchangeComponent,
    SvgInfoComponent,
    SvgInstagramLinkComponent,
    SvgLabResultComponent,
    SvgLinkedInLinkComponent,
    SvgMedicationComponent,
    SvgMembershipAndBillingComponent,
    SvgMessageComponent,
    SvgNotificationSettingsComponent,
    SvgPhoneInBlueCircle,
    SvgPhoneInGreenCircle,
    SvgProfileComponent,
    SvgProgressPieComponent,
    SvgRequestRecordsComponent,
    SvgStethoscopeComponent,
    SvgTwitterLinkComponent,
    SvgVaccineComponent,
    SvgVirtualVisitComponent,
    SvgYoutubeLinkComponent,
    SvgWatchComponent,
    ToastComponent,
    ToggleCaretComponent,
    ToggleComponent,
    TosComponent,
    TruncatedMarkdown,
    UrlToLinkPipe,
    UserOnCompComponent,
    ValidationXComponent,
    XWithCircleComponent,
    DirectSignupBannerComponent,
    PasswordInputComponent,
    ShowPasswordComponent,
    ModalTemplateComponent,
    SvgCaretUpComponent,
    SvgCaretDownComponent,
    AddressDisplayComponent,
    DetectResizeDirective,
    ViewMoreComponent,
    DropdownFormComponent,
    OmguiBadgeComponent,
    SvgVaccinesComponent,
    LabelWithTooltipComponent,
    TooltipComponent,
    LoadingSpinnerComponent,
    EmailVerificationComponent,
    SvgSecurityComponent,
    DateFormatPipe,
    SafeHtmlPipe,
    DownloadAppModalComponent,
    DownloadAppModalContent,
    LottieComponent,
    DateInputPickerComponent,
    EnterViewportObserverDirective,
    SvgMessageOutlineComponent,
    NavbarContainerComponent,
    NavbarDesktopComponent,
    NavbarMobileComponent,
  ],
  imports: [
    AgmCoreModule.forRoot(),
    CommonModule,
    FormsModule,
    NgbModule,
    ReactiveFormsModule,
    NgrxFormsModule,
    RouterModule,
    RecaptchaModule,
    OmguiModule,
    OmguiSummaryRowModule,
    PipesModule,
    SvgCaretRightModule,
    CardListModule,
    DescriptionListCardModule,
    CardTableModule,
    TrackLinkModule,
    LetModule,
  ],
  providers: [
    AnonymousFlagDataGraphQL,
    AnonymousFlagDataService,
    AppStoreRedirectService,
    AppointmentSearchService,
    AppointmentSearchStateService,
    CreateHealthInformationReleaseAuthorizationGraphqlService,
    ContentBlockService,
    CouponService,
    DetectResizeService,
    FeatureFlagGraphQL,
    FeatureFlagByB2bIDGraphQL,
    FeatureFlagSelectors,
    FooterAnalyticsService,
    HealthInformationReleaseService,
    NativeAppService,
    MembershipGraphQL,
    OfficeService,
    PatientExperimentService,
    PaymentModalAnalyticsService,
    ProviderService,
    PusherService,
    ServiceAreaService,
    StateService,
    StripeService,
    TaskService,
    {
      provide: LAZY_MAPS_API_CONFIG,
      useClass: MapsApiConfig,
      deps: [ConfigService],
    },
  ],
  exports: [
    AccordionComponent,
    AddressAutocompleteComponent,
    AddressBasicInputComponent,
    AddressDisplayComponent,
    AddressOptionInputComponent,
    AddToCalendarComponent,
    AmfValuePropsComponent,
    AppointmentConfirmationModalComponent,
    ArrowBackComponent,
    ArrowLeftComponent,
    ArrowNextComponent,
    ArrowRightComponent,
    BackButtonComponent,
    CalendarComponent,
    CardComponent,
    CardDirective,
    CardListModule,
    CardSliderComponent,
    CardTableModule,
    CarouselModalComponent,
    CheckboxComponent,
    CheckboxInputComponent,
    CheckmarkListComponent,
    CircleWithCheckmarkComponent,
    CloseComponent,
    ConfirmationModalComponent,
    CovidVaccinationNextSteps,
    CreditCardComponent,
    DateInputComponent,
    DeleteConfirmationInlineFormComponent,
    DirectSignupComponent,
    AdultDirectSignupFormComponent,
    IconItemComponent,
    DescriptionListCardModule,
    DirectSignupBannerComponent,
    DownloadAppCardComponent,
    DownloadAppLinkInputComponent,
    EmailInputComponent,
    EmailVerificationRegistrationComponent,
    ExcerptPipe,
    ExclamationInBubbleComponent,
    ExpandingTextBox,
    ExpiredMembershipBannerComponent,
    FileSizePipe,
    FlashComponent,
    FocusTargetDirective,
    HealthInformationReleaseFormComponent,
    EnterViewportObserverDirective,
    InfoCardBodyDirective,
    InfoCardComponent,
    InfoCardHeaderDirective,
    InlineFormComponent,
    InternalRedirectPromptModalComponent,
    InviteFriendsModalComponent,
    LabelWrapper,
    LegalAgreementComponent,
    LegalDocComponent,
    LocationOnMapComponent,
    LoggedInWrapperComponent,
    LoginPromptModalComponent,
    LogoComponent,
    LogoSmComponent,
    LogoSpinner,
    LogoutPromptModalComponent,
    LottieComponent,
    SidebarComponent,
    MomentPipe,
    AppointmentMoreOptionsComponent,
    NameInputComponent,
    NavbarComponent,
    NextRemoteVisitComponent,
    OfficeDetailsComponent,
    OmRecaptchaComponent,
    OmguiCheckboxComponent,
    OmguiClearableTextInputComponent,
    OmguiSummaryRowModule,
    PaginationComponent,
    PasswordInputComponent,
    PencilComponent,
    PhoneComponent,
    PhoneNumberInputComponent,
    PhoneNumberPipe,
    PhoneNumberToLinkPipe,
    ProfileBubbleWithDetailsComponent,
    ProviderComponent,
    ProgramPromotionCardComponent,
    RadioInputNgrxComponent,
    RadioGroupComponent,
    ReferralBannerComponent,
    RoutingGroupComponent,
    RoutingGroupOptionComponent,
    SelfPayConfirmationComponent,
    SeparatorComponent,
    ServiceAreaSelectComponent,
    SexGenderInputComponent,
    SmallCardComponent,
    SmallCardSvgDirective,
    SpecialtyComponent,
    StartCasePipe,
    StripeCreditCardComponent,
    StripePaymentRequestButtonComponent,
    StyledMapComponent,
    SubmitButton,
    Svg,
    SvgActionItemComponent,
    SvgAfterpayLogoComponent,
    SvgAllergyComponent,
    SvgApplepayLogoComponent,
    SvgBikeComponent,
    SvgVirtualVisitComponent,
    SvgVitalsComponent,
    SvgCaretDownComponent,
    SvgCaretRightModule,
    SvgCaretUpComponent,
    SvgCheckmarkComponent,
    SvgCircleWithExclamationPointComponent,
    SvgCopyIcon,
    SvgGermComponent,
    SvgDownloadAppComponent,
    SvgEyeClosedComponent,
    SvgEyeOpenComponent,
    SvgExclamationComponent,
    SvgFamilyComponent,
    SvgGiftBoxIcon,
    SvgHamburgerComponent,
    SvgHealthInformationExchangeComponent,
    SvgInfoComponent,
    SvgLabResultComponent,
    SvgMedicationComponent,
    SvgMembershipAndBillingComponent,
    SvgMessageComponent,
    SvgNotificationSettingsComponent,
    SvgPhoneInBlueCircle,
    SvgPhoneInGreenCircle,
    SvgProfileComponent,
    SvgProgressPieComponent,
    SvgVaccineComponent,
    SvgStethoscopeComponent,
    ToastComponent,
    ToggleCaretComponent,
    ToggleComponent,
    TosComponent,
    TruncatedMarkdown,
    UrlToLinkPipe,
    UserOnCompComponent,
    ValidationXComponent,
    XWithCircleComponent,
    ReferralBannerComponent,
    ModalTemplateComponent,
    DetectResizeDirective,
    ViewMoreComponent,
    DropdownFormComponent,
    OmguiBadgeComponent,
    LabelWithTooltipComponent,
    TooltipComponent,
    LoadingSpinnerComponent,
    EmailVerificationComponent,
    NextRemoteVisitMultipleSlotsComponent,
    DateFormatPipe,
    SafeHtmlPipe,
    DownloadAppModalComponent,
    DateInputPickerComponent,
    SvgMessageOutlineComponent,
    NavbarContainerComponent,
    NavbarDesktopComponent,
    NavbarMobileComponent,
  ],
})
export class SharedModule {}
