import { Injectable } from '@angular/core';

import { ConfigService } from '@app/core/config.service';
import { HEALTH_HISTORY_ADULT_SURVEY_ID } from '@app/home/home-page/home-page.constants';
import { SurveyIds } from '@app/survey/survey-models';
import { appendQueryParamsToPath } from '@app/utils/link.utils';

@Injectable({
  providedIn: 'root',
})
export class LinksService {
  // Emails
  readonly adminEmail = 'mailto:admin@onemedical.com';

  // Onelife Links
  readonly onelife = {
    homePage: `${this.config.json.myoneServer}/pt/my-one/index`,
    logInUrl: `${this.config.json.myoneServer}/pt/patient/login`,
    resetPassword: `${this.config.json.myoneServer}/pt/auth/forgot_password`,
    medicalRecords: `${this.config.json.myoneServer}/pt/questionnaire/medical-records-release`,
    fsaHsaReimbursement: `${this.config.json.myoneServer}/pt/questionnaire/fsa-hsa-reimbursement`,
    choosePcp: `${this.config.json.myoneServer}/pt/my-one/physicians`,
    providerTeam: `${this.config.json.myoneServer}/pt/my-one/provider_team`,
  };

  // Patient UI Paths
  readonly home = `/`;
  readonly login = '/login';
  readonly membershipRenewConsumer = `/membership/renew/consumer`;
  readonly membershipRenewEnterprise = `/membership/renew/enterprise`;
  readonly membershipRenewOMNow = `/membership/renew/omnow`;
  readonly membershipSettings = `/membership/settings`;
  readonly verifyEmail = '/membership/verify-email';
  readonly registrationEnterprise = `/registration/enterprise`;
  readonly registrationPediatric = `/registration/pediatric`;
  readonly registrationConsumer = `/register`;
  readonly newAppointment = `/appointments/new`;
  readonly requestRecords = '/account/request-records';
  readonly healthSurvey = this.survey(HEALTH_HISTORY_ADULT_SURVEY_ID);
  readonly medicalRecordsRelease = this.survey(SurveyIds.MEDICAL_RECORDS_RELEASE);
  readonly travelSurvey = this.survey(SurveyIds.TRAVEL);
  readonly referrals = '/registration/referrals';
  readonly processAfterpay = `${window.location.origin}/register`;
  readonly myProvider = '/account/my-provider';
  readonly selectProvider = '/providers/select-provider';
  readonly providerHub = '/providers/provider-hub';
  readonly onboarding = '/onboarding';
  readonly appStoreRedirect = '/app-store-redirect';
  readonly appointmentsPage = '/appointments/list';
  readonly tasks = '/tasks';
  readonly messages = '/messages';

  // External links
  readonly afterpay = 'https://portal.afterpay.com/us/login-email';
  readonly COVID_RESOURCES = 'https://onemedical.com/coronavirus';

  constructor(private config: ConfigService) {}

  survey(surveyId: string): string {
    return `/survey/${surveyId}`;
  }

  appointmentConfirmation(id: string): string {
    return `/appointments/${id}/confirmation`;
  }

  employeeIdRegistration(b2bCompanyId: number): string {
    return `${this.registrationEnterprise}/employee_id?b2b_company_id=${b2bCompanyId}`;
  }

  loginWithQueryParams(queryParams?: Record<string, string>) {
    return appendQueryParamsToPath(this.login, queryParams);
  }

  onboardingWithQueryParams(queryParams?: Record<string, string>) {
    return appendQueryParamsToPath(this.onboarding, queryParams);
  }

  registrationPediatricWithQueryParams(queryParams?: Record<string, string>) {
    return appendQueryParamsToPath(this.registrationPediatric, queryParams);
  }

  registrationEnterpriseWithQueryParams(queryParams?: Record<string, string>) {
    return appendQueryParamsToPath(this.registrationEnterprise, queryParams);
  }
}
