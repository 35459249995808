import { Injectable } from '@angular/core';
import { createFeatureSelector, createSelector, select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, tap } from 'rxjs/operators';

import { FeatureFlagActions } from '@app/core/feature-flags/feature-flag.actions';
import { FeatureFlagState } from '@app/core/feature-flags/feature-flag.reducer';

export const selectFeatureFlagState = createFeatureSelector<FeatureFlagState>('featureFlags');

export const getFeatureFlag = (props: { flag: string }) =>
  createSelector(selectFeatureFlagState, (state: FeatureFlagState) => state.flags[props.flag]);

@Injectable({
  providedIn: 'root',
})
export class FeatureFlagSelectors {
  constructor(private store: Store<FeatureFlagState>, private featureFlagActions: FeatureFlagActions) {}

  getFeatureFlag<T>(flag: string, defaultValue?: T): Observable<T> {
    return this.store.pipe(
      select(getFeatureFlag({ flag })),
      tap(flagValue => {
        if (flagValue === undefined) {
          this.featureFlagActions.loadFeatureFlag(flag, defaultValue);
        }
      }),
      filter(flagValue => flagValue !== undefined),
    );
  }
}
