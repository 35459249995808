import { ENVIRONMENT_QUAL } from './qual';

export const environment = {
  ...ENVIRONMENT_QUAL,
  apiServer: 'https://okay.app.1life.com',
  myoneServer: 'https://okay.app.1life.com',
  rollbarEnvironment: 'onelife-okay',
  launchDarklyClientId: '60503484e13fe80c650be99a',
  stripePublishableKey: 'pk_test_Jdbev53YaXb1eOrqznxCYgTs',
};
