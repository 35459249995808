<ng-template #brand>
  <a
    routerLink="/"
    [omTrackLink]="trackHomeLink"
    class="navbar-brand home"
    data-cy="om-logo-nav"
    [class.disabled]="inactiveMembership"
  >
    <div class="d-block d-md-none d-lg-block"><om-logo></om-logo></div>
    <div class="d-none d-md-block d-lg-none"><om-logo-sm></om-logo-sm></div>
  </a>
</ng-template>

<nav
  aria-label="Main Nav"
  data-cy="main-nav"
  class="navbar full d-flex navbar-expand-md justify-content-between bg-white fixed-top shadow-sm"
>
  <ng-container *ngTemplateOutlet="brand"></ng-container>

  <ng-container *rxLet="isHomeScreenRedesignOn$ as isRedesignOn">
    <ul class="navbar-nav d-flex align-items-center">
      <li class="nav-item">
        <a
          [routerLink]="navbarRoutes.Home"
          [omTrackLink]="trackHomeLink"
          data-cy="home-nav"
          class="nav-link text-link home"
          [class.disabled]="inactiveMembership"
        >
          Home
        </a>
        <div class="active-section" [class.active]="routeInfo[navbarRoutes.Home].isActive"></div>
      </li>
      <li class="nav-item">
        <a
          [routerLink]="navbarRoutes.HealthRecord"
          [omTrackLink]="trackHealthRecordLink"
          data-cy="health-record-nav"
          class="nav-link text-link health-record"
        >
          Health Record
        </a>
        <div class="active-section" [class.active]="routeInfo[navbarRoutes.HealthRecord].isActive"></div>
      </li>
      <ng-container *ngIf="isRedesignOn; else currentOrder">
        <ng-container *ngTemplateOutlet="carePlanItem"></ng-container>
        <ng-container *ngTemplateOutlet="messagesItem"></ng-container>
      </ng-container>
      <li class="nav-item">
        <a
          routerLink="/appointments/new"
          [omTrackLink]="trackBookVisitLink"
          data-cy="book-visit-nav"
          class="nav-link btn btn-outline-primary book-visit"
          [class.disabled]="!canBookVisit"
        >
          Get Care
        </a>
      </li>
      <li class="nav-item" ngbDropdown display="dynamic" placement="bottom-right">
        <button
          aria-label="Profile Menu Toggle"
          class="nav-link navbar-menu rounded profile-menu-button"
          ngbDropdownToggle
          id="profile-menu-label"
          data-cy="profile-menu-toggle"
        >
          <omgui-profile-bubble
            aria-hidden="true"
            [profile]="profile"
            [colorCombo]="colorComboSeed"
            class="navbar-menu"
            data-cy="profile-menu-nav"
          ></omgui-profile-bubble>
        </button>
        <div
          class="dropdown-menu"
          ngbDropdownMenu
          aria-label="Profile Menu"
          aria-labelledby="profile-menu-label"
          data-cy="profile-menu"
        >
          <a class="dropdown-item" routerLink="/account/profile" data-cy="account-nav">Account</a>
          <a class="dropdown-item" routerLink="/appointments/list" data-cy="appointments-nav">Appointments</a>
          <a *ngIf="!virtual" class="dropdown-item" routerLink="/account/my-provider" data-cy="provider-nav">
            Provider
          </a>
          <a
            *ngIf="enrolledInMindsetPlus"
            class="dropdown-item"
            routerLink="/programs/mindset+"
            [omTrackLink]="trackMindsetSelected"
            data-cy="program-hub-nav"
          >
            Mindset+
          </a>
          <a
            *ngIf="canRegisterKid"
            class="dropdown-item register-child"
            routerLink="/registration/pediatric"
            [omTrackLink]="trackRegisterChildLink"
            data-cy="consumer-ped-reg-nav"
          >
            Sign up your kid
          </a>
          <a
            *ngIf="directSignupEligible"
            class="dropdown-item"
            [routerLink]="links.referrals"
            [omTrackLink]="trackDirectSignupLink"
            data-cy="family-nav"
          >
            Register Family Member
          </a>
          <button class="dropdown-item inline-icon-with-text" (click)="inviteModalClicked.emit()" data-cy="invite-nav">
            {{ inviteCta }}
            <om-svg-gift-box-icon fill="currentColor" class="nav-icon ml-3"></om-svg-gift-box-icon>
          </button>
          <a class="dropdown-item" href="#" (click)="logoutClicked.emit($event)" data-cy="log-out-nav">Log Out</a>
        </div>
      </li>
    </ul>
  </ng-container>
</nav>

<ng-template #currentOrder>
  <ng-container *ngTemplateOutlet="messagesItem"></ng-container>
  <ng-container *ngTemplateOutlet="carePlanItem"></ng-container>
</ng-template>

<ng-template #messagesItem>
  <li class="nav-item">
    <a
      [routerLink]="navbarRoutes.Messages"
      [omTrackLink]="trackMessagesLink"
      data-cy="messages-nav"
      class="nav-link text-link messages"
    >
      Messages
    </a>
    <div class="active-section" [class.active]="routeInfo[navbarRoutes.Messages].isActive"></div>
  </li>
</ng-template>

<ng-template #carePlanItem>
  <li class="nav-item">
    <a
      [routerLink]="navbarRoutes.CarePlan"
      [omTrackLink]="trackTasksLink"
      data-cy="care-plan-nav"
      class="nav-link text-link tasks"
    >
      Care Plan
    </a>
    <div class="active-section" [class.active]="routeInfo[navbarRoutes.CarePlan].isActive"></div>
  </li>
</ng-template>
